import { default as cookieszHDexH0PsLMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue?macro=true";
import { default as cookies3U8iSxD8OXMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue?macro=true";
import { default as index1oPH2YnifnMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue?macro=true";
import { default as legalUQt6w3l8jIMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue?macro=true";
import { default as portfolio_45about2CuFONGtRXMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue?macro=true";
import { default as portfolio_45feesXhHK49ZYOkMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorsJJCVUkmRR9Meta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuersvwi7MezooFMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersI44ecwHFG9Meta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue?macro=true";
import { default as indexvSIGaSBc8ZMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reitZgkZ0Ic3ZaMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersW9wUyLG2J3Meta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue?macro=true";
import { default as privacy8t2N8Ca2zwMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue?macro=true";
import { default as indexFf6vMogQABMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue?macro=true";
import { default as legalmUHjbP5IDNMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue?macro=true";
import { default as portfolio_45aboutv9oBzcK5nQMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue?macro=true";
import { default as portfolio_45feesNnXJXYduAQMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue?macro=true";
import { default as portfolio_45investors1E6XnyBhyRMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuersFawYiHdLDnMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersHdpIWGw46wMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue?macro=true";
import { default as indexuUyrsfe62dMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reitU4dDTh9QACMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersG6bBaQ5BrsMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue?macro=true";
import { default as privacy6i4TfkzgIgMeta } from "/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue?macro=true";
export default [
  {
    name: cookieszHDexH0PsLMeta?.name ?? "cookies",
    path: cookieszHDexH0PsLMeta?.path ?? "/cookies",
    meta: cookieszHDexH0PsLMeta || {},
    alias: cookieszHDexH0PsLMeta?.alias || [],
    redirect: cookieszHDexH0PsLMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies3U8iSxD8OXMeta?.name ?? "es-cookies",
    path: cookies3U8iSxD8OXMeta?.path ?? "/es/cookies",
    meta: cookies3U8iSxD8OXMeta || {},
    alias: cookies3U8iSxD8OXMeta?.alias || [],
    redirect: cookies3U8iSxD8OXMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue").then(m => m.default || m)
  },
  {
    name: index1oPH2YnifnMeta?.name ?? "es",
    path: index1oPH2YnifnMeta?.path ?? "/es",
    meta: index1oPH2YnifnMeta || {},
    alias: index1oPH2YnifnMeta?.alias || [],
    redirect: index1oPH2YnifnMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue").then(m => m.default || m)
  },
  {
    name: legalUQt6w3l8jIMeta?.name ?? "es-legal",
    path: legalUQt6w3l8jIMeta?.path ?? "/es/legal",
    meta: legalUQt6w3l8jIMeta || {},
    alias: legalUQt6w3l8jIMeta?.alias || [],
    redirect: legalUQt6w3l8jIMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45about2CuFONGtRXMeta?.name ?? "es-portfolio-about",
    path: portfolio_45about2CuFONGtRXMeta?.path ?? "/es/portfolio-about",
    meta: portfolio_45about2CuFONGtRXMeta || {},
    alias: portfolio_45about2CuFONGtRXMeta?.alias || [],
    redirect: portfolio_45about2CuFONGtRXMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesXhHK49ZYOkMeta?.name ?? "es-portfolio-fees",
    path: portfolio_45feesXhHK49ZYOkMeta?.path ?? "/es/portfolio-fees",
    meta: portfolio_45feesXhHK49ZYOkMeta || {},
    alias: portfolio_45feesXhHK49ZYOkMeta?.alias || [],
    redirect: portfolio_45feesXhHK49ZYOkMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorsJJCVUkmRR9Meta?.name ?? "es-portfolio-investors",
    path: portfolio_45investorsJJCVUkmRR9Meta?.path ?? "/es/portfolio-investors",
    meta: portfolio_45investorsJJCVUkmRR9Meta || {},
    alias: portfolio_45investorsJJCVUkmRR9Meta?.alias || [],
    redirect: portfolio_45investorsJJCVUkmRR9Meta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuersvwi7MezooFMeta?.name ?? "es-portfolio-issuers",
    path: portfolio_45issuersvwi7MezooFMeta?.path ?? "/es/portfolio-issuers",
    meta: portfolio_45issuersvwi7MezooFMeta || {},
    alias: portfolio_45issuersvwi7MezooFMeta?.alias || [],
    redirect: portfolio_45issuersvwi7MezooFMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersI44ecwHFG9Meta?.name ?? "es-portfolio-members",
    path: portfolio_45membersI44ecwHFG9Meta?.path ?? "/es/portfolio-members",
    meta: portfolio_45membersI44ecwHFG9Meta || {},
    alias: portfolio_45membersI44ecwHFG9Meta?.alias || [],
    redirect: portfolio_45membersI44ecwHFG9Meta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: indexvSIGaSBc8ZMeta?.name ?? "es-portfolio-news",
    path: indexvSIGaSBc8ZMeta?.path ?? "/es/portfolio-news",
    meta: indexvSIGaSBc8ZMeta || {},
    alias: indexvSIGaSBc8ZMeta?.alias || [],
    redirect: indexvSIGaSBc8ZMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reitZgkZ0Ic3ZaMeta?.name ?? "es-portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reitZgkZ0Ic3ZaMeta?.path ?? "/es/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reitZgkZ0Ic3ZaMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reitZgkZ0Ic3ZaMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reitZgkZ0Ic3ZaMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersW9wUyLG2J3Meta?.name ?? "es-portfolio-partners",
    path: portfolio_45partnersW9wUyLG2J3Meta?.path ?? "/es/portfolio-partners",
    meta: portfolio_45partnersW9wUyLG2J3Meta || {},
    alias: portfolio_45partnersW9wUyLG2J3Meta?.alias || [],
    redirect: portfolio_45partnersW9wUyLG2J3Meta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacy8t2N8Ca2zwMeta?.name ?? "es-privacy",
    path: privacy8t2N8Ca2zwMeta?.path ?? "/es/privacy",
    meta: privacy8t2N8Ca2zwMeta || {},
    alias: privacy8t2N8Ca2zwMeta?.alias || [],
    redirect: privacy8t2N8Ca2zwMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexFf6vMogQABMeta?.name ?? "index",
    path: indexFf6vMogQABMeta?.path ?? "/",
    meta: indexFf6vMogQABMeta || {},
    alias: indexFf6vMogQABMeta?.alias || [],
    redirect: indexFf6vMogQABMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalmUHjbP5IDNMeta?.name ?? "legal",
    path: legalmUHjbP5IDNMeta?.path ?? "/legal",
    meta: legalmUHjbP5IDNMeta || {},
    alias: legalmUHjbP5IDNMeta?.alias || [],
    redirect: legalmUHjbP5IDNMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutv9oBzcK5nQMeta?.name ?? "portfolio-about",
    path: portfolio_45aboutv9oBzcK5nQMeta?.path ?? "/portfolio-about",
    meta: portfolio_45aboutv9oBzcK5nQMeta || {},
    alias: portfolio_45aboutv9oBzcK5nQMeta?.alias || [],
    redirect: portfolio_45aboutv9oBzcK5nQMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesNnXJXYduAQMeta?.name ?? "portfolio-fees",
    path: portfolio_45feesNnXJXYduAQMeta?.path ?? "/portfolio-fees",
    meta: portfolio_45feesNnXJXYduAQMeta || {},
    alias: portfolio_45feesNnXJXYduAQMeta?.alias || [],
    redirect: portfolio_45feesNnXJXYduAQMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investors1E6XnyBhyRMeta?.name ?? "portfolio-investors",
    path: portfolio_45investors1E6XnyBhyRMeta?.path ?? "/portfolio-investors",
    meta: portfolio_45investors1E6XnyBhyRMeta || {},
    alias: portfolio_45investors1E6XnyBhyRMeta?.alias || [],
    redirect: portfolio_45investors1E6XnyBhyRMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuersFawYiHdLDnMeta?.name ?? "portfolio-issuers",
    path: portfolio_45issuersFawYiHdLDnMeta?.path ?? "/portfolio-issuers",
    meta: portfolio_45issuersFawYiHdLDnMeta || {},
    alias: portfolio_45issuersFawYiHdLDnMeta?.alias || [],
    redirect: portfolio_45issuersFawYiHdLDnMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersHdpIWGw46wMeta?.name ?? "portfolio-members",
    path: portfolio_45membersHdpIWGw46wMeta?.path ?? "/portfolio-members",
    meta: portfolio_45membersHdpIWGw46wMeta || {},
    alias: portfolio_45membersHdpIWGw46wMeta?.alias || [],
    redirect: portfolio_45membersHdpIWGw46wMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: indexuUyrsfe62dMeta?.name ?? "portfolio-news",
    path: indexuUyrsfe62dMeta?.path ?? "/portfolio-news",
    meta: indexuUyrsfe62dMeta || {},
    alias: indexuUyrsfe62dMeta?.alias || [],
    redirect: indexuUyrsfe62dMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reitU4dDTh9QACMeta?.name ?? "portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reitU4dDTh9QACMeta?.path ?? "/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reitU4dDTh9QACMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reitU4dDTh9QACMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reitU4dDTh9QACMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersG6bBaQ5BrsMeta?.name ?? "portfolio-partners",
    path: portfolio_45partnersG6bBaQ5BrsMeta?.path ?? "/portfolio-partners",
    meta: portfolio_45partnersG6bBaQ5BrsMeta || {},
    alias: portfolio_45partnersG6bBaQ5BrsMeta?.alias || [],
    redirect: portfolio_45partnersG6bBaQ5BrsMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacy6i4TfkzgIgMeta?.name ?? "privacy",
    path: privacy6i4TfkzgIgMeta?.path ?? "/privacy",
    meta: privacy6i4TfkzgIgMeta || {},
    alias: privacy6i4TfkzgIgMeta?.alias || [],
    redirect: privacy6i4TfkzgIgMeta?.redirect,
    component: () => import("/codebuild/output/src2617048980/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue").then(m => m.default || m)
  }
]