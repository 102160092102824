<template lang="pug">
.switch(@click.stop="click")
  .switch__button(:class="{ 'disabled': props.disabled }")
    input(
      :id="props.name"
      v-model="modelValue"
      :name="props.name"
      :disabled="props.disabled"
      type="checkbox")
    .slider

  label(:for="props.name")
    slot
</template>

<script lang="ts" setup>
export interface Props {
  disabled?: boolean,
  name?: string,
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  name: 'switch',
})

const emit = defineEmits<{
  (e: 'click', value: boolean): void
}>()

const modelValue = defineModel<boolean>({ required: true })

const click = () => {
  if (props.disabled) return
  emit('click', modelValue.value)
}
</script>

<style lang="scss" scoped>
// Switch checkbox
$switchWidth: double();
$switchHeight: simple(1.2);
$sliderBorder: 4px;
$sliderSize: calc(#{$switchHeight} - #{$sliderBorder * 2});
$sliderTranslate: calc(#{$switchWidth} - #{$sliderBorder * 2} - #{$sliderSize});

.switch {
  display: flex;
  align-items: center;
  column-gap: half();
}

.switch__button {
  position: relative;
  display: inline-block;
  width: $switchWidth;
  height: $switchHeight;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: color(gray7);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: $switchHeight;

    &:before {
      position: absolute;
      content: "";
      height: $sliderSize;
      width: $sliderSize;
      left: $sliderBorder;
      bottom: $sliderBorder;
      background-color: color(white);
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked[type="checkbox"]~.slider {
    background-color: color(brand);
  }

  input:checked[type="checkbox"]~.slider:before {
    -webkit-transform: translateX($sliderTranslate);
    -ms-transform: translateX($sliderTranslate);
    transform: translateX($sliderTranslate);
  }
}

.disabled {
  .slider {
    background-color: color(gray5);
  }

  input:checked[type="checkbox"]~.slider {
    background-color: color(gray5);
  }

  input:checked[type="checkbox"]~.slider:before {
    background-color: color(gray7);
  }
}
</style>
