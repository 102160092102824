<template lang="pug">
li.accordionItem(:class="{ 'is-open': isOpen }")
  //-
  //- The header of the accordion
  //-
  h3
    button.accordionItem__header(
      :id="'header-' + id"
      :aria-controls="'content-' + id"
      :aria-expanded="isOpen"
      @click="toggle"
    )
      svg.accordionItem__triger(
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="3"
        stroke="hsl(194, 15%, 36%)"
        aria-hidden="true")
        path(
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m19.5 8.25-7.5 7.5-7.5-7.5")

      slot(name="header")

  //-
  //- The body of the accordion
  //-
  section.accordionItem__body(
    :id="'content-' + id"
    :aria-labelledby="'header-' + id"
    :aria-hidden="!isOpen"
    :inert="!isOpen"
    role="region"
  )
    .accordionItem__animation
      .accordionItem__wrapper
        .accordionItem__content
          slot
</template>

<script setup lang="ts">
interface Props {
  panelIndex?: number
  isActive?: boolean,
  multiple?: boolean
}

const instance = getCurrentInstance()
const props = defineProps<Props>()
const emit = defineEmits<{
  toggle: [index: number]
}>()

const isOpen = ref(false);
const id = ref(instance?.uid ?? 0)

const toggle = () => {
  isOpen.value = !isOpen.value

  if (props.panelIndex !== undefined) emit('toggle', props.panelIndex)
}

watch(() => props.isActive, (isActive) => {
  isOpen.value = isActive
})

onMounted(() => {
  if (props.panelIndex) id.value = props.panelIndex
})
</script>

<style lang="scss" scoped>
.accordionItem {
  margin-bottom: 0;
  padding-left: 0;
  border-bottom: 1px solid color(gray8);

  h3 {
    margin-bottom: 0;
    font-size: font-size(base);
    font-weight: font-weight(semibold);
  }

  &::before {
    display: none;
  }

  &.is-open {
    border-bottom-color: transparent;

    .accordionItem__triger {
      transform: rotate(0deg);
    }

    .accordionItem__body {
      grid-template-rows: 1fr;
    }

    .accordionItem__wrapper {
      transform: translateY(0);
      visibility: visible;
      transition: transform 0.5s ease-out, visibility 0s linear;
    }
  }

  &:has(+ .accordionItem .accordionItem__header:hover),
  &:has(.accordionItem__header:hover) {
    border-bottom-color: transparent;
  }
}

.accordionItem__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: double();
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  width: 100%;
  color: color(gray1);
  padding-left: size(1.5);
  font-weight: font-weight(semibold);
  border-radius: $border-radius;

  &:hover:not(:disabled) {
    background-image: none;
    background-color: color(gray8);
    color: color(gray1);
  }

  &:focus-visible {
    outline: 2px solid color(brand);
    box-shadow: 0 0 0 6px white;
  }
}

.accordionItem__triger {
  position: absolute;
  display: inline-block;
  width: half();
  margin-right: half();
  transition: 0.3ms easy;
  stroke: color(gray3);
  stroke-width: 2;
  transform: rotate(-90deg);
  left: half();
  top: 0;
  height: double();
}

.accordionItem__body {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
  overflow: hidden;
}

.accordionItem__animation {
  min-height: 0;
}

.accordionItem__wrapper {
  transform: translateY(-100%);
  visibility: hidden;
  transition: transform 0.5s ease-out, visibility 0.5s ease;
}

.accordionItem__content {
  overflow: hidden;
  font-size: font-size(small1);
  line-height: 1.2rem;
  color: color(gray4);
  padding: simple();
  background-color: $color-background;
  border-radius: $border-radius;
  margin-bottom: half();

  :deep(p:last-of-type) {
    margin-bottom: 0;
  }

  :deep(th) {
    font-size: font-size(small1);
  }

  :deep(td) {
    border-color: color(gray7);
  }
}
</style>
