import html2pdf from 'html2pdf.js'

// had to make a plugin because directly importing html2pdf.js in the component
// where I need to use it was causing an error as the import would run on the server
// side and html2pdf.js is a client-side-only library. This plugin runs on the
// client side only (due to the .client extension) so it works fine.
export default defineNuxtPlugin(() => {
  return {
    provide: {
      html2pdf: () => {
        return html2pdf()
      }
    }
  }
})
