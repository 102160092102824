<template lang="pug">
.accordion
  h2.accordion__head
    slot(name="head") {{ title }}

  ul.accordion__content(
    :id="'accordion-' + id"
    role="presentation")
    renderedSlots
</template>

<script setup lang="ts">
import AccordionItem from './AccordionItem.vue';

const instance = getCurrentInstance()
const props = defineProps({
  title: { type: String, default: '' },
  multiple: Boolean
});

const slots = useSlots()

const id = ref(instance?.uid ?? 0)
const openPanels = ref<number[]>([])

const togglePanel = (index: number) => {
  if (openPanels.value.includes(index)) {
    openPanels.value = openPanels.value.filter((i) => i !== index)
  } else {
    openPanels.value = !props.multiple ? [index] : [...openPanels.value, index]
  }
}

const renderedSlots = () => {
  if (!slots.default) return null

  return slots.default().map((slot, index) => {
    if (slot.type !== AccordionItem) {
      console.error('Accordion only accepts AccordionItem components')
      return null
    }

    return h(slot, {
      ...slot.props,
      panelIndex: id.value + index,
      isActive: openPanels.value.includes(index),
      multiple: props.multiple,
      onToggle: () => togglePanel(index)
    })
  })
};


</script>

<style lang="scss" scoped>
.accordion__head {
  font-size: font-size(h3);
  font-weight: font-weight(semibold);
}

.accordion__content {
  list-style: none;
}
</style>
