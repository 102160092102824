<template lang="pug">
section.errorPage
  .errorPage__wrapper
    .errorPage__logo
      img(
        src="/images/portfolio_logo.svg"
        alt="Portfolio Exchange")
    template(v-if="error?.statusCode === 404")
      h1.errorPage__title {{ $t('pages.error.404.title') }}
      div
        button.button.margin-right(@click="handleError()") {{ $t('pages.error.404.home') }}
        NuxtLink.button(
          to="https://app.portfolio.exchange/public/markets"
          target="_blank"
          external) {{ $t('pages.market.title') }}

    template(v-else)
      h1.errorPage__title {{ $t('pages.error.500.title') }}
      p.errorPage__description {{ $t('pages.error.500.description') }}
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'

const nuxtApp = useNuxtApp()
const route = useRoute()
const i18n = nuxtApp.$i18n
const { defaultLocale, locales, locale } = useI18n()
const cookieLocale = useCookie('locale')

const error = useError();

const handleError = () => {
  const path = locale.value === defaultLocale ? '/' : `/${locale.value}`

  clearError({
    redirect: path,
  });
};

function getUrlLocale(path: string, defaultLocale: string, locales: LocaleObject[]) {
  const urlLocale = path.split('/')[1]
  if (!urlLocale || urlLocale.length !== 2) return defaultLocale
  return locales.find(item => item.code === urlLocale) ? urlLocale : defaultLocale
}

onMounted(async () => {
  const path = route.fullPath
  const pathLocale = getUrlLocale(path, defaultLocale, locales.value)

  if (pathLocale !== locale.value) {
    i18n.setLocale(pathLocale)
    cookieLocale.value = pathLocale
  }
});
</script>

<style lang="scss" scoped>
.errorPage {
  background-image: url('/images/pattern-lines.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.errorPage__wrapper {
  max-width: 48rem;
  margin: 0 auto;
  padding: triple() simple();
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.errorPage__logo {
  margin-bottom: double();

  img {
    width: auto;
    height: 5rem;
  }
}

.errorPage__title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.6rem;
}

.errorPage__description {
  font-size: font-size(h3);
  color: color(gray2);
  line-height: 2rem;
}

.button {
  margin-top: size(2);
}
</style>
