<template lang="pug">
div
  Html(
    :lang="head.htmlAttrs.lang"
    :dir="head.htmlAttrs.dir")
    Head
      Title {{ title }}
      template(
        v-for="link in head.link"
        :key="link.id")
        Link(
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang")

      template(
        v-for="meta in head.meta"
        :key="meta.id")
        Meta(
          :id="meta.id"
          :property="meta.property"
          :content="meta.content")

    Body
      NuxtLoadingIndicator
      NuxtLayout
        NuxtPage

      Cookies
</template>

<script setup lang="ts">
import '~/assets/styles/main.scss'
import type { LocaleObject } from '@nuxtjs/i18n'

const nuxtApp = useNuxtApp()
const route = useRoute()
const i18n = nuxtApp.$i18n
const { t, defaultLocale, locales, locale } = useI18n()
const browserLocale = useBrowserLocale()
const cookieLocale = useCookie('locale')

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})

const title = computed(() => {
  if (route.meta.title === undefined) return t('meta.title')
  return `Portfolio | ${t(route.meta.title as string)}`
})

const description = computed(() => {
  if (route.meta.description === undefined) return t('meta.description')
  return `${t(route.meta.description as string)}`
})

useSeoMeta({
  title: title.value,
  description: description.value,
  ogSiteName: 'Portfolio',
  ogType: 'website',
  ogImage: '/images/portfolio-banner.jpg',
  ogImageWidth: '1200',
  ogImageHeight: '630',
  twitterCard: 'summary_large_image',
  twitterSite: '@Portfolio_SE',
  twitterImage: '/images/portfolio-banner.jpg'
})

useHead({
  title: title.value,
  meta: [
    { name: 'description', content: description.value },
    { property: "og:description", content: description.value },
    { property: "og:title", content: title.value },
    { property: "twitter:description", content: description.value },
    { property: "twitter:title", content: title.value },
  ],
  script: [
    { src: '/portfolio.config.js', type: "text/javascript" },
  ],
  link: [
    { rel: 'icon', type: 'image/svg+xml', href: '/images/appFavicon.svg' },
    { rel: 'apple-touch-icon', href: '/images/appFavicon.svg' },
  ],
})

function getUrlLocale(path: string, defaultLocale: string, locales: LocaleObject[]) {
  const urlLocale = path.split('/')[1]
  if (!urlLocale || urlLocale.length !== 2) return defaultLocale
  return locales.find(item => item.code === urlLocale) ? urlLocale : defaultLocale
}

onMounted(async () => {
  let path = route.fullPath
  const pathLocale = getUrlLocale(path, defaultLocale, locales.value)
  const userLocale = cookieLocale.value ?? browserLocale ?? defaultLocale

  // Set the new locale if it's different from the current one
  if (userLocale !== locale.value) {
    i18n.setLocale(userLocale)
    cookieLocale.value = userLocale
  }

  // Redirect if the user locale is different from the path locale
  if (userLocale !== pathLocale) {
    // If the user locale is defaultLocale remove the locale from the path
    // Otherwise, if the pathLocale is defaultLocale add the user locale to the path
    // Otherwise, replace the path locale with the user locale
    if (userLocale === defaultLocale) {
      path = path.replace(`/${pathLocale}`, '')
    } else {
      if (pathLocale === defaultLocale) {
        path = `/${userLocale}${path}`
      } else {
        path = path.replace(`/${pathLocale}`, `/${userLocale}`)
      }
    }

    await navigateTo({ path: path })
  }
})
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
