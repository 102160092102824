// const { locale, defaultLocale, locales } = useI18n()
import type { LocaleObject } from "@nuxtjs/i18n";

/**
 * Rewrites a given path to include the correct locale prefix according to the rules:
 * - If the selected locale is the default locale, the path will not have a locale prefix.
 * - If the selected locale is not the default, the path will include the selected locale as a prefix.
 * - This ensures that paths are correctly formatted based on the application's current and default locales.
 *
 * @param path - The relative path which may or may not include a locale prefix.
 * @param locale - The currently selected locale (e.g., 'es', 'en', 'fr').
 * @param defaultLocale - The default locale of the application (e.g., 'es', 'en', 'fr').
 * @param supportedLocales - An array of supported locale strings (e.g., ['es', 'en', 'fr']).
 */
function rewritePathWithLocale(path: string, locale: string, defaultLocale: string, supportedLocales: string[]): string {
  // Check if the path is not a relative URL (it must start with '/').
  if (!path.startsWith('/')) {
    return path;
  }

  // Check if the selected locale is supported, use the default locale if not
  if (!supportedLocales.includes(locale)) {
    locale = defaultLocale;
  }

  // Build a regex pattern to identify if the path already has a locale prefix
  const localesList = supportedLocales.join('|');
  const localePrefixPattern = new RegExp(`^/(${localesList})(/|$)`);

  // Check if the current path has a locale prefix
  const hasLocalePrefix = localePrefixPattern.test(path);

  if (locale === defaultLocale) {
    // If the selected locale is the same as the default locale
    // and the path has a locale prefix, we remove it.
    if (hasLocalePrefix) {
      return path.replace(localePrefixPattern, '/');
    }
    // If it does not have a prefix, we return it as it is.
    return path;
  } else {
    // If the selected locale is not the default locale,
    // we need to ensure that the path has the correct prefix.
    if (hasLocalePrefix) {
      // If it already has a locale prefix, we replace it with the selected locale.
      return path.replace(localePrefixPattern, `/${locale}$2`);
    } else {
      // If it does not have a locale prefix, we simply add it to the beginning.
      return `/${locale}${path}`;
    }
  }
}

export const useLocalizePath = (path: string, locale: string, defaultLocale: string, locales: LocaleObject[]) => {
  const supportedLocales = locales.map((locale) => locale.code)
  return rewritePathWithLocale(path, locale, defaultLocale, supportedLocales)
}
