<template lang="pug">
.cookies(v-if="showCookies")
  .cookies__bar
    .cookies__bar__content
      .cookies__bar__text
        | {{ $t('cookies.bar') }}
        br
        | {{ $t('cookies.bar2') }}

      .cookies__bar__actions
        .button.margin-right(@click="acceptAllCookies") {{ $t('cookies.confirm') }}
        .button.button--gray(@click="manageCookies = true") {{ $t('cookies.manage') }}

  .cookies__modal(v-if="manageCookies")
    .cookies__container
      Accordion(
        :title="$t('cookies.title')"
        multiple)

        Accordion-item
          template(#header) {{ $t('cookies.privacy.title') }}

          p {{ $t('cookies.privacy.description') }}

        Accordion-item
          template(#header)
            div {{ $t('cookies.cookies.title') }}

            SwitchButton(
              v-model="cookies.necessary"
              name="necessary"
              disabled)

          p {{ $t('cookies.cookies.description') }}
          TechnicalCookies.margin-top

        Accordion-item
          template(#header)
            div {{ $t('cookies.analytics.title') }}

            SwitchButton(
              v-model="cookies.analytics"
              name="analytics")

          p {{ $t('cookies.analytics.description1') }}
          p {{ $t('cookies.analytics.description2') }}

          ThirdPartyCookies.margin-top

        Accordion-item
          template(#header)
            div {{ $t('cookies.cookiesPolicy.title') }}


          i18n-t(
            keypath="cookies.cookiesPolicy.description"
            tag="p"
            for="cookies.cookiesPolicy.link"
            scope="global")
            a(@click="goToCookiesPolicy") {{ $t('cookies.cookiesPolicy.link') }}

      .cookies__action.margin-top-double
        a.cookies__action__link(@click="rejectCookies") {{ $t('cookies.reject') }}
        .button(@click="saveCookies") {{ $t('cookies.save') }}
</template>

<script lang="ts" setup>
type Cookies = {
  necessary: boolean
  analytics: boolean
}

const { gtag } = useGtag()
const router = useRouter()
const { locale, defaultLocale, locales } = useI18n()

const showCookies = ref(false)
const manageCookies = ref(false)
const cookies = ref<Cookies>({
  necessary: true,
  analytics: true,
})

/**
 * Check if cookies are already saved in local storage
 * If not, show the cookies bar
 */
function checkCookies() {
  try {
    const storageCookies = localStorage.getItem('cookies')
    const cookiesData = storageCookies ? (JSON.parse(storageCookies) as Cookies) : null

    if (!cookiesData) showCookies.value = true
    else cookies.value = cookiesData
  } catch (error) {
    clearCookies()
  }
}

/**
 * Clear cookies from local storage
 * This will show the cookies bar again
 */
function clearCookies() {
  showCookies.value = true
  localStorage.removeItem('cookies')
}

function goToCookiesPolicy() {
  manageCookies.value = false
  const localePath = computed(() => useLocalizePath('/cookies', locale.value, defaultLocale, locales.value))

  router.push({ path: localePath.value })
}

/**
 * Consent granted for analytics cookies
 *
 * There are some consent options that can be used
 * https://developers.google.com/gtagjs/devguide/consent
 * https://developers.google.com/tag-platform/tag-manager/templates/consent-apis
 *
 * - ad_user_date: Sets consent for sending user data to Google for online advertising purposes
 * - ad_personalization: Sets consent for personalized advertising.
 * - ad_storage: Enables storage, such as cookies, related to advertising.
 * - analytics_storage: Enables storage, such as cookies, related to analytics (for example, visit duration).
 * - security_storage: Enables storage related to security such as authentication functionality, fraud prevention, and other user protection
 * - personalization_storage: Enables storage related to personalization such as video recommendations.
 * - functionality_storage: Enables storage that supports the functionality of the website or app such as language settings.
 */
function consentGranted() {
  gtag('consent', 'update', {
    analytics_storage: 'granted'
  })
}

/**
 * Revoke consent for analytics cookies
 *
 * There are some consent options that can be used
 * https://developers.google.com/gtagjs/devguide/consent
 * https://developers.google.com/tag-platform/tag-manager/templates/consent-apis
 *
 * - ad_user_date: Sets consent for sending user data to Google for online advertising purposes
 * - ad_personalization: Sets consent for personalized advertising.
 * - ad_storage: Enables storage, such as cookies, related to advertising.
 * - analytics_storage: Enables storage, such as cookies, related to analytics (for example, visit duration).
 * - security_storage: Enables storage related to security such as authentication functionality, fraud prevention, and other user protection
 * - personalization_storage: Enables storage related to personalization such as video recommendations.
 * - functionality_storage: Enables storage that supports the functionality of the website or app such as language settings.
 */
function revokeConsent() {
  gtag('consent', 'update', {
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    ad_storage: 'denied',
    analytics_storage: 'denied',
    security_storage: 'denied',
    personalization_storage: 'denied',
    functionality_storage: 'denied',
  })
}

/**
 * Close cookies bar
 */
function closeCookies() {
  showCookies.value = false
}

/**
 * Save cookies preferences
 */
function saveCookies() {
  if (cookies.value.analytics) consentGranted()
  else revokeConsent()

  localStorage.setItem('cookies', JSON.stringify(cookies.value))
  closeCookies()
}

/**
 * Accept all cookies
 */
function acceptAllCookies() {
  consentGranted()
  cookies.value.analytics = true
  localStorage.setItem('cookies', JSON.stringify(cookies.value))
  closeCookies()
}

/**
 * Reject optional cookies
 */
function rejectCookies() {
  revokeConsent()
  cookies.value.analytics = false
  localStorage.setItem('cookies', JSON.stringify(cookies.value))
  closeCookies()
}

onMounted(() => {
  checkCookies()
})
</script>

<style lang="scss" scoped>
.cookies__bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 90000;
  width: 100vw;
  transition: all 0.3s;
  background-color: #15191a;
}

.cookies__bar__content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: half();
  color: color(white);
  font-size: font-size(small1);
  font-weight: font-weight(light);
  line-height: 1.1rem;
  column-gap: simple();
}

.cookies__modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}

.cookies__container {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 49rem;
  max-height: 90vh;
  padding: triple();
  overflow: auto;
  font-weight: font-weight(light);
  background-color: color(white);
  border-radius: $border-radius-big;
  box-shadow:
    0 5px 40px 2px #0e1416,
    0 3px 20px 1px #0e1416;
  transition: all 0.3s;

  p,
  ul,
  li {
    font-size: font-size(small1);
    line-height: 1.2rem;
  }
}

.cookies__action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: simple();
}

.cookies__action__link {
  color: color(gray4);
  text-decoration: underline;

  &:hover {
    color: color(brand-light);
  }
}

.button {
  height: inherit;
  padding: half() simple();
  font-weight: font-weight(semibold);
  font-size: font-size(small1);
  border-radius: $border-radius-small;
}

.button--gray {
  background-image: radial-gradient(circle at 0% 0%, color(gray3), color(gray2));
}
</style>
